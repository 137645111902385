import { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import { DEFAULT_COUNTRY_CALL_CODE } from "@/lib/constants";
import { UserSegment } from "@/lib/user-segment";
import { getUserID } from "@/lib/utils";

/**
 * @typedef SessionUser
 * @property {string | null} name
 * @property {string | null } email
 * @property {string | null} image
 */

/**
 * @typedef User
 * @property {string} id
 * @property {string | null} name
 * @property {string | null} firstName
 * @property {string | null} lastName
 * @property {string | null } email
 * @property {string | null} image
 * @property {string} countryCallCode
 * @property {string} mobileNo
 */

/**
 * Check if an user is an internal staff.
 * @param {User | null} user
 * @returns {boolean}
 */
const checkInternalUser = (user) => {
  if (!user) return false;

  const { email } = user;
  if (!email) return false;

  if (email.includes("@justship.sg")) return true;

  return false;
};

export const useUser = () => {
  // Ref: https://next-auth.js.org/getting-started/client#usesession
  const { data: session, status: authStatus } = useSession();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!session) return;

    /* @type {SessionUser} */
    const sessionUser = session?.user;
    if (!sessionUser) return;

    const { user_meta, user_segment } = session;
    const {
      country_code = "",
      subscriber_number = "",
      first_name = "",
      last_name = "",
    } = user_meta || {};

    setUser({
      ...sessionUser,
      id: getUserID(session),
      firstName: first_name || "",
      lastName: last_name || "",
      countryCallCode: country_code || DEFAULT_COUNTRY_CALL_CODE,
      mobileNo: subscriber_number || "",
      segment: user_segment,
    });
  }, [session]);

  const isShopifyUser = session ? session?.is_shopify_user : undefined;

  return {
    user,
    authStatus,
    isInternalUser: session ? checkInternalUser(session?.user) : undefined,
    isShopifyUser,
    isEcommerceUser:
      user?.segment === UserSegment.SellingEcommerce || isShopifyUser,
  };
};
